import { lazy, useEffect, useState } from "react";

import useFirebase from "hooks/useFirebase";

const AuthComponent = lazy(() => import("pages/auth"));
const ProjectComponent = lazy(() => import("pages/projects"));

export default function App() {
  const [, auth] = useFirebase();

  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setIsLoggedIn(!!user);
      setLoading(false);
    });
    //setIsLoggedIn(true)
    //setLoading(false)
  }, [auth]);

  return (
    <Choose>
      <When condition={loading}>
        <div className="flex items-center justify-center h-full text-3xl font-bold">
          Unpacking .....
        </div>
      </When>
      <Otherwise>
        <Choose>
          <When condition={isLoggedIn}>
            <ProjectComponent />
          </When>
          <Otherwise>
            <AuthComponent />
          </Otherwise>
        </Choose>
      </Otherwise>
    </Choose>
  );
}
